import * as React from 'react';
import { HtmlHeadInclude, IRequestContext } from '@msdyn365-commerce/core';
import MsDyn365 from '@msdyn365-commerce/core';

const isDev = false; // process.env.NODE_ENV === 'development';

type SharedScriptsProps = {
    googleTagManagerId?: string;
    bazaarVoiceEnv?: string;
    request: IRequestContext;
    sailthruCustomerId?: string;
};

const bvHandlerScript = `
window.bvCallback = function (BV) {
    BV.reviews && BV.reviews.on('show', function () {
        var elem = document.getElementsByClassName("hei-bv-reviews")[0];
        elem && elem.click();
    });
}
    `.trim();

const klaviyoInitScript = `
!function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();
    `.trim();

const SharedScripts = ({ googleTagManagerId, bazaarVoiceEnv, sailthruCustomerId, request }: SharedScriptsProps) => {
    const { locale } = request;

    const userEmail = request.user.emailAddress;

    const bvLocale = (locale || 'en-CA').replace('-', '_');
    const bvScript = `https://apps.bazaarvoice.com/deployments/henrys/main_site/${bazaarVoiceEnv || 'staging'}/${bvLocale}/bv.js`;

    React.useEffect(() => {
        if (googleTagManagerId && !isDev) {
            (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s) as HTMLScriptElement,
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode?.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', googleTagManagerId);
        }
    }, [googleTagManagerId]);

    const [klaviyoPublicKey, klaviyoScript] = React.useMemo(() => {
        const publicKey: string = request.app.config.heiKlaviyoPublicKey;
        process.env.NODE_ENV === 'development' && console.log('klaviyo', publicKey);

        if (publicKey) {
            let script = klaviyoInitScript;
            if (request.user.emailAddress) {
                const user = request.user;
                script += `
  klaviyo.identify({
    'email' : '${user.emailAddress}',
    'first_name' : '${user.firstName?.replace("'", "\\'")}',
    'last_name' : '${user.lastName?.replace("'", "\\'")}'
  });
  `.trim();
            }

            return [publicKey, script];
        }

        if (process.env.NODE_ENV === 'development') {
            return ['RLch2r', klaviyoInitScript];
        }

        return ['', ''];
    }, []);

    process.env.NODE_ENV === 'development' && console.log('klaviyo', 'klaviyoPublicKey', klaviyoPublicKey);

    // if (isDev) {
    //     React.useEffect(() => {
    //         const bodyTag = document.getElementsByTagName('body')[0];

    //         const script = document.createElement('script');
    //         script.async = true;
    //         script.src = `/assets/gtm.js`;
    //         bodyTag.appendChild(script);
    //     }, []);
    // }

    // if (process.env.NODE_ENV === 'development') {
    //     userEmail = 'elin@bicameral.ca';
    // }

    var isBrowser = MsDyn365.isBrowser;
    React.useEffect(() => {
        if (sailthruCustomerId && isBrowser && !window.sailthruInit && window.Sailthru) {
            window.sailthruInit = true;
            window.Sailthru.init({ customerId: sailthruCustomerId });
        }
    }, [isBrowser, sailthruCustomerId]);

    React.useEffect(() => {
        if (userEmail && isBrowser && window.sailthruInit && window.Sailthru && !request.cookies.get('sailthru_hid').value) {
            // Set SailThru hid
            window.Sailthru.integration('userSignUp', {
                email: userEmail,
                vars: {}
            });
        }
    }, [isBrowser, userEmail]);

    return (
        <>
            <HtmlHeadInclude>
                {/* tslint:disable-next-line: react-no-dangerous-html */}
                <script data-load-point='headStart' dangerouslySetInnerHTML={{ __html: bvHandlerScript }} />
                <script data-load-point='headStart' src={bvScript} />

                <script data-load-point='headEnd' src='https://ak.sail-horizon.com/spm/spm.v1.min.js' async></script>

                {klaviyoPublicKey && (
                    <script
                        data-load-point='bodyEnd'
                        type='text/javascript'
                        async
                        data-script-id='klaviyo'
                        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoPublicKey}`}
                    ></script>
                )}
                {klaviyoScript && (
                    <script
                        data-load-point='bodyEnd'
                        dangerouslySetInnerHTML={{
                            __html: klaviyoScript
                        }}
                    />
                )}
            </HtmlHeadInclude>
        </>
    );
};

export default SharedScripts;
